import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { createPortal } from 'react-dom';
import { css } from '@emotion/css';
import { getPortalContainer, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';

export const Tooltip = forwardRef((props, ref) => {
  const domRef = useRef<HTMLDivElement>(null);
  const portalRoot = useRef<HTMLElement | null>(null);

  if (portalRoot.current == null) {
    portalRoot.current = getPortalContainer();
  }

  const styles = useStyles2(getStyles);

  useImperativeHandle(ref, () => {
    return {
      show: (content: string, xPos: number, yPos: number) => {
        if (domRef.current) {
          domRef.current.innerHTML = content;
          domRef.current.style.display = 'block';
          domRef.current.style.top = `${yPos}px`;
          if (window.innerWidth < xPos + domRef.current.offsetWidth) {
            domRef.current.style.left = `${xPos - domRef.current.offsetWidth - 12}px`;
          } else {
            domRef.current.style.left = `${xPos + 12}px`;
          }
        }
      },
      hide: () => {
        if (domRef.current) {
          domRef.current.style.display = 'none';
        }
      },
    };
  });

  return createPortal(
    <div
      className={styles.tooltipWrapper}
      aria-live="polite"
      aria-atomic="true"
      style={{ display: 'none' }}
      ref={domRef}
    ></div>,
    portalRoot.current
  );
});
Tooltip.displayName = 'Tooltip';

const getStyles = (theme: GrafanaTheme2, maxWidth?: number) => ({
  tooltipWrapper: css({
    top: 0,
    left: 0,
    zIndex: theme.zIndex.portal,
    whiteSpace: 'pre',
    borderRadius: theme.shape.radius.default,
    position: 'fixed',
    background: theme.colors.background.primary,
    border: `1px solid ${theme.colors.border.weak}`,
    boxShadow: theme.shadows.z2,
    userSelect: 'text',
    maxWidth: maxWidth ?? 'none',
    padding: '2px 8px',
  }),
});
