import React, { MouseEvent, useMemo, useRef } from 'react';
import { dateTime, PanelProps } from '@grafana/data';
import { PillPanelOptions } from 'types';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { PanelDataErrorView } from '@grafana/runtime';
import { Tooltip } from './Tooltip';
import { Legend } from './Legend';
import { Axis } from './Axis';

interface Props extends PanelProps<PillPanelOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans, serif;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
    `,
    svg: css`
      order: 3;
      * {
        transform-box: fill-box;
      }
    `,
    pill: css`
      transition: all 0.1s;
      transform-origin: center;
      &:hover {
        transform: scale(1.25, 1.1);
        opacity: 0.9;
      }
    `,
  };
};

export const PillPanel: React.FC<Props> = ({ options, data, width, height, fieldConfig, id }) => {
  const styles = useStyles2(getStyles);

  const tooltipRef: any = useRef(null);

  const showTooltip = (evt: MouseEvent<Element>) => {
    const tooltipText = evt.currentTarget.getAttribute('data-tooltip');
    if (tooltipText) {
      tooltipRef.current?.show(tooltipText, evt.pageX, evt.pageY);
    }
  };

  const hideTooltip = () => {
    tooltipRef.current?.hide();
  };

  const { pillWidth, pillSpacing, legend, axis } = options;

  const pillCount = Math.floor((width - pillSpacing) / (pillWidth + pillSpacing));

  const vizSeries = useMemo(
    () =>
      data.series.map((frame) => ({
        ...frame,
        fields: frame.fields.map((field) => ({
          ...field,
          values: field.values.splice(-pillCount),
        })),
      })),
    [data.series, pillCount]
  );

  console.log(vizSeries);

  const plotData = useMemo(
    () =>
      vizSeries[0].fields[0].values.map((timestamp, index) => {
        const displayFn = vizSeries[0].fields[1].display;
        const display = displayFn!(vizSeries[0].fields[1].values[index]);
        return {
          timestamp: dateTime(timestamp).format('YYYY-MM-DD HH:mm:ss.SSS'),
          text: display.text,
          color: display.color,
        };
      }),
    [vizSeries]
  );

  console.log(plotData.length);

  if (vizSeries.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  // Leave space below svg for legend
  height = Math.max(height, 100);
  const svgHeight = height - (legend.showLegend ? 42 : 0) - (axis.showAxis ? 42 : 0);
  const pillHeight = Math.floor(svgHeight * 0.9);
  const pillRadius = pillWidth / 4;

  return (
    <div className={styles.wrapper}>
      <svg
        className={styles.svg}
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox={`0 0 ${width} ${svgHeight}`}
      >
        <g>
          {plotData.map(({ timestamp, text, color }, index) => (
            <rect
              className={styles.pill}
              data-tooltip={`${timestamp} - ${text}`}
              data-testid="simple-panel-circle"
              key={index}
              x={index * (pillWidth + pillSpacing)}
              y={pillHeight * 0.05}
              style={{ fill: color }}
              width={pillWidth}
              height={pillHeight}
              rx={pillRadius}
              ry={pillRadius}
              onMouseEnter={showTooltip}
              onMouseLeave={hideTooltip}
            />
          ))}
        </g>
      </svg>
      <Axis dataFrame={vizSeries[0]} options={axis} />
      <Legend dataFrame={vizSeries[0]} options={legend} />
      <Tooltip ref={tooltipRef} />
    </div>
  );
};
