import { LegendOptions } from '../types';
import { useStyles2, useTheme2 } from '@grafana/ui';
import React, { useMemo } from 'react';
import { css } from '@emotion/css';
import { DataFrame, ValueMapping } from '@grafana/data';

const getStyles = () => {
  return {
    legend: css`
      display: flex;
      width: 100%;
      padding: 8px;
      .item {
        display: flex;
        align-items: center;
        margin-left: 8px;
        &:first-child {
          margin-left: 0;
        }
      }
      .pill {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        margin-right: 8px;
      }
    `,
  };
};

export const Legend = ({ dataFrame, options }: { dataFrame: DataFrame; options: LegendOptions }) => {
  const theme = useTheme2();
  const styles = useStyles2(getStyles);

  const legendItems = useMemo(() => valueMappingToLegendItems(dataFrame.fields[1].config.mappings), [dataFrame]);

  if (!options.showLegend) {
    return null;
  }

  return (
    <div className={styles.legend} style={{ order: options.placement === 'bottom' ? 5 : 1 }}>
      {legendItems.map(({ text, color, index }) => (
        <div key={index} className="item">
          <div
            className="pill"
            style={{
              backgroundColor: theme.visualization.getColorByName(color!),
            }}
          ></div>
          {text}
        </div>
      ))}
    </div>
  );
};

function valueMappingToLegendItems(mappings: ValueMapping[] = []) {
  const legendItems: any[] = [];
  mappings.forEach((mapping) => {
    if (mapping.type === 'value') {
      legendItems.push(...Object.values(mapping.options));
    } else {
      legendItems.push(mapping.options.result);
    }
  });
  return legendItems;
}
