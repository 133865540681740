import { DataFrame, dateTime } from '@grafana/data';
import { AxisOptions } from '../types';
import React, { useMemo } from 'react';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';

const getStyles = () => {
  return {
    axis: css`
      display: flex;
      width: 100%;
      padding: 8px;
      .legend-end {
        margin-left: auto;
      }
    `,
  };
};

export const Axis = ({ dataFrame, options }: { dataFrame: DataFrame; options: AxisOptions }) => {
  const styles = useStyles2(getStyles);

  const { axisStart, axisEnd } = useMemo(
    () => ({
      axisStart: dateTime(dataFrame.fields[0].values[0]).fromNow(),
      axisEnd: dateTime(dataFrame.fields[0].values[-1]).fromNow(),
    }),
    [dataFrame]
  );

  if (!options.showAxis) {
    return null;
  }

  return (
    <div className={styles.axis} style={{ order: options.placement === 'bottom' ? 4 : 2 }}>
      <span>{axisStart}</span>
      <span className="legend-end">{axisEnd}</span>
    </div>
  );
};
